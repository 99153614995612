import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
// TODO: Remove dummy data when API is ready
import data from './agri-dummy-data.json';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'uni-item-source-details-modal',
    templateUrl: './item-source-details-modal.component.html',
    styleUrl: './item-source-details-modal.component.sass',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemSourceDetailsModalComponent implements IUniModal, OnInit, OnDestroy {
    baseUrl = environment.BASE_URL;
    options: IModalOptions = {};
    fieldData: any = data;
    fields$ = new BehaviorSubject(this.fieldData);
    itemSource$ = new BehaviorSubject({});
    onClose = new EventEmitter<any>();

    constructor(private http: HttpClient) {
        this.fields$.next(data);
        this.initialize();
    }

    ngOnInit() {
        const isJournalEntry = this.options.data?.isJournalEntry;
        const isJournalLines = this.options.data?.isJournalLines;

        if (this.options.data?.items) {
            this.itemSource$.next(this.options.data?.items);
        }

        if (this.options.data.readonly || isJournalEntry) {
            const disableFields = this.fields$.getValue().map((field, index) => ({
                ...field,
                ['ReadOnly']: isJournalEntry && !this.options.data.readonly && index > 2 ? false : true,
            }));
            this.fields$.next(disableFields);
        } else if (isJournalLines) {
            const enableFields = this.fields$.getValue().map((field) => ({
                ...field,
                ['ReadOnly']: false,
            }));
            this.fields$.next(enableFields);
        }
    }

    ngOnDestroy() {
        this.fields$.complete();
        this.itemSource$.complete();
    }

    checkForSaveKey(event) {
        const key = event.which || event.keyCode;

        if (key === 83 && (navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey)) {
            event.preventDefault();
            const activeElement: any = document.activeElement;

            if (activeElement?.blur) {
                activeElement.blur();
            }

            // Give components a chance to update disabled state
            // because there might be changes triggered by ctrl+s (table blur etc)
            setTimeout(() => {
                this.save();

                if (activeElement?.focus) {
                    activeElement.focus();
                }
            });
        }
    }

    save() {
        this.onClose.emit(this.itemSource$.getValue());
        this.fields$.next({});
    }

    private initialize() {
        const fields = this.fields$.getValue();
        fields.map((field) => {
            if (field.Url) {
                const isAbsoluteUrl = field?.Url.includes('https');
                const url = isAbsoluteUrl ? field.Url : `/api/biz${field.Url}`;
                field.Options = {
                    source: () => this.http.get(url),
                    template: (item) => this.getPropValues(item, field.Options.itemTemplate),
                    ...field.Options,
                };
            }
            return field;
        });

        this.fields$.next(fields);
    }

    private getPropValues(item: any, field: string[]) {
        const propertyNames = field;
        const propertyValues = propertyNames.map((propertyName) => item[propertyName]);
        return propertyValues.join(' - ');
    }
}
