import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ElsaProduct } from '@app/models';

@Component({
    selector: 'uni-select-products',
    templateUrl: './select-products.component.html',
    styleUrls: ['./select-products.component.sass'],
})
export class SelectProductsComponent {
    @Input() products: ElsaProduct[];
    @Input() selectedProducts: ElsaProduct[] = [];
    @Output() selectedProductsChange = new EventEmitter<ElsaProduct[]>();

    onSelectionChange(product: ElsaProduct) {
        if (product['_disabled']) return;
        product['_selected'] = !product['_selected'];
        const selectedProducts = this.products.filter((p) => p['_selected']);
        this.selectedProductsChange.emit(selectedProducts);
    }
}
