import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { BizHttp } from '../../../framework/core/http/BizHttp';
import { Company, CompanySettings, Address } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@app/authService';

@Injectable({ providedIn: 'root' })
export class CompanyService extends BizHttp<Company> {
    ELSA_SERVER_URL = environment.ELSA_SERVER_URL;

    constructor(
        http: UniHttp,
        private commonHttp: HttpClient,
        private authService: AuthService,
    ) {
        super(http);
        this.relativeURL = Company.RelativeUrl;
        this.entityType = Company.EntityType;
        this.DefaultOrderBy = null;

        // Tell BizHttp to only clear cache on logout (not company change)
        this.cacheSettings.clearOnlyOnLogout = true;
    }

    createCompany(
        companyName: string,
        companySettings: CompanySettings,
        contractID: number,
        productNames: string,
        isTemplate: boolean = false,
    ) {
        return this.http
            .asPUT()
            .withEndPoint('companies?action=create-company')
            .withBody({
                CompanyName: companyName,
                CompanySettings: companySettings,
                ContractID: contractID,
                ProductNames: productNames,
                IsTemplate: isTemplate,
            })
            .send()
            .pipe(
                map((response) => {
                    super.invalidateCache();
                    return response.body;
                }),
            );
    }

    activateFileFlow() {
        return this.PutAction(this.authService.activeCompany.ID, 'create-update-email');
    }

    deactivateFileFlow() {
        return this.PutAction(this.authService.activeCompany.ID, 'disable-email');
    }

    /**
     * @returns JobID that can be queried for progress
     */
    createFromTemplate(
        templateCompanyKey: string,
        companySettings: CompanySettings,
        productNames: string[],
        contractID: number,
    ): Observable<number> {
        const address = companySettings.DefaultAddress || <Address>{};
        const body = {
            TemplateCompanyKey: templateCompanyKey,
            CompanyDetails: {
                ContractID: contractID,
                ProductNames: productNames,
                CompanyName: companySettings.CompanyName,
                OrganizationNumber: companySettings.OrganizationNumber,
                Address: address.AddressLine1,
                PostalCode: address.PostalCode,
                City: address.City,
            },
        };

        const endpoint = environment.UNI_JOB_SERVER_URL + 'jobs?job=ImportFromTemplate';
        return this.http
            .asPOST()
            .withBody(body)
            .sendToUrl(endpoint)
            .pipe(
                tap(() => super.invalidateCache()),
                map((jobID) => +jobID),
            );
    }

    updateCompanyClientNumber(companyID: number, clientNumber: number, key) {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`companies/${companyID}?action=clientnumber&clientnumber=${clientNumber}`)
            .withHeader('CompanyKey', key)
            .send({}, null, false)
            .pipe(
                tap(() => super.invalidateCache()),
                map((res) => res.body),
            );
    }

    deleteCompany(companyKey: string) {
        return this.http
            .asDELETE()
            .usingBusinessDomain()
            .withEndPoint(`companies?action=delete-company&key=${companyKey}`)
            .send({}, null, false)
            .pipe(tap(() => super.invalidateCache()));
    }

    restoreCompany(companyKey: string) {
        return this.http
            .asPUT()
            .usingBusinessDomain()
            .withEndPoint(`companies?action=undelete-company&key=${companyKey}`)
            .send({}, null, false)
            .pipe(tap(() => super.invalidateCache()));
    }

    restoreCompanyBackup(companyId: number) {
        return this.http
            .asPUT()
            .usingElsaDomain()
            .withEndPoint(`/api/companylicenses/${companyId}/restore?backup=true`)
            .send({}, null, false)
            .pipe(tap(() => super.invalidateCache()));
    }

    validateCompany() {
        return this.http
            .asPOST()
            .usingBusinessDomain()
            .withEndPoint(`companies?action=validate`)
            .send()
            .pipe(
                tap(() => super.invalidateCache()),
                map((res) => res.body),
            );
    }

    deleteCompanyWithBackup(companyID: number, backupYears: number) {
        const companyIDs = [{ ID: companyID }];
        const params = `?backup=true&backupfiles=true&backupYears=${backupYears}`;

        return this.http
            .asPUT()
            .usingElsaDomain()
            .withEndPoint(`/api/companylicenses/delete` + params)
            .withBody(companyIDs)
            .send();
    }

    updateCompanyLicense(companyID: number, body) {
        return this.commonHttp
            .put(this.ELSA_SERVER_URL + `/api/companylicenses/${companyID}`, body)
            .pipe(map((res) => res[0]));
    }

    moveCompanyLicense(companyID: number, customerID: number, contractID: number) {
        return this.commonHttp.put(
            this.ELSA_SERVER_URL + `/api/companylicenses/${companyID}/move-to-customer/${customerID}/${contractID}`,
            null,
        );
    }
}
