<ul class="uni-company-modal-selection-list">
    <li
        *ngFor="let product of products"
        (click)="onSelectionChange(product)"
        [ngClass]="{
            selected: product['_selected'],
            disabled: product['_disabled'],
        }"
    >
        <i class="material-icons">
            {{ product['_selected'] ? 'check_box' : 'check_box_outline_blank' }}
        </i>

        {{ product.Label }}
    </li>
</ul>
